import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { TextInput } from "../../components/Form/TextInput";
import { GET_TEMPLATE } from "../../graphql/Queries/TemplateNotificationQuery";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { MODIFY_TEMPLATE } from "../../graphql/Mutations/TemplateNotificationMutation";
import { Editor } from "../../components/Form/Editor";

export const TemplateNotificationsForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_TEMPLATE);
  const { data } = useQuery(GET_TEMPLATE, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      subject: {
        az: "",
        en: "",
        ru: "",
      },
      title: {
        az: "",
        en: "",
        ru: "",
      },
      text: {
        az: "",
        en: "",
        ru: "",
      },
      comment: "",
      channel: "web",
    },
    validationSchema: Yup.object({
      // subject: Yup.object().shape({
      //   az: Yup.string().required(t("Объязательное поле")),
      // }),
      text: Yup.object().shape({
        az: Yup.string().required(t("Объязательное поле")),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          subject: JSON.stringify(values.subject),
          title: JSON.stringify(values.title),
          text: JSON.stringify(values.text),
          comment: values.comment,
          channel: values.channel,
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (data && data.templateNotification[0]) {
      data.templateNotification[0].subject.map((dic) => {
        formik.setFieldValue(`subject.${dic.lang}`, dic.value);
      });
      data.templateNotification[0].title.map((dic) => {
        formik.setFieldValue(`title.${dic.lang}`, dic.value);
      });
      data.templateNotification[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      formik.setFieldValue("id", data.templateNotification[0].id);
      formik.setFieldValue("comment", data.templateNotification[0].comment);
      formik.setFieldValue("channel", data.templateNotification[0].channel);
    }
  }, [data]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить шаблон" : "Редактировать шаблон"}
        breadcrumbs={[
          {
            slug: url,
            name: "Шаблоны уведомлений",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Тема"}
                    value={formik.values.subject}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`subject.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.subject}
                    touched={formik.touched.subject}
                    name={"subject"}
                  />
                </div>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Заголовок"}
                    value={formik.values.title}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`title.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.title}
                    touched={formik.touched.title}
                    name={"title"}
                  />
                </div>
                <div className="col-md-12">
                  <Editor
                    value={formik.values.text}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`text.${lang}`, text);
                    }}
                    name={"text"}
                    label={"Текст"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Комментарий"}
                    value={formik.values.comment}
                    onChange={(event) => {
                      formik.setFieldValue(`comment`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.comment}
                    touched={formik.touched.comment}
                    name={"comment"}
                  />
                </div>
                <div className="col-md-6">
                  <Selected
                    label={"Канал"}
                    onChange={(value) => {
                      formik.setFieldValue(`channel`, value);
                    }}
                    error={formik.errors.channel}
                    touched={formik.touched.channel}
                    name={"channel"}
                    rows={{ value: "id", label: "name" }}
                    staticOptions={[
                      { id: "web", name: "сайт" },
                      { id: "email", name: "email" },
                      { id: "sms", name: "смс" },
                    ]}
                    value={formik.values.channel}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
