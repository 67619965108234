import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ESTATES } from "../graphql/Queries/EstateQuery";
import moment from "moment";
import { DELETE_ESTATE } from "../graphql/Mutations/EstateMutation";
import i18next from "i18next";
import { setBadge } from "../redux/slices/badgeSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateQuery } from "../redux/slices/tableSlice";

export const Estates = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const searchTimer = useRef(null);
  const query = useSelector((state) => state.table.estates);
  const [sort, setSort] = useState(false);
  const [totalEstates, setTotalEstates] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("[Log]", query);
  }, [query]);

  const { data } = useQuery(GET_ESTATES, {
    fetchPolicy: "no-cache",
    variables: {
      page: currentPage,
      query: JSON.stringify(query),
      sort: !sort ? "" : JSON.stringify(sort),
      lang: i18next.language,
    },
  });
  const [drop] = useMutation(DELETE_ESTATE);

  useEffect(() => {
    if (data) {
      setItems(data.estate.data);
      setTotalEstates(data?.estate?.total);
      const badge = JSON.parse(data.badge);
      dispatch(setBadge(badge));
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
        page: currentPage,
        query: JSON.stringify(query),
        sort: !sort ? "" : JSON.stringify(sort),
        lang: i18next.language,
      },
    })
      .then((response) => {
        setItems(response.data.deleteEstate.data);
        setTotalEstates(response.data.deleteEstate.total);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Объвления"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                totalPage={totalEstates}
                data={items}
                initSearchObject={query}
                columns={[
                  {
                    key: "id",
                    label: "ID",
                    callback: (item, row) => {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {(Number(row.status_id) === 2 ||
                            Number(row.status_id) === 6) && (
                            <i
                              className={"ri-alert-line"}
                              style={{ color: "red", marginRight: "5px" }}
                            />
                          )}
                          {item}
                        </div>
                      );
                    },
                  },
                  {
                    key: "published_at",
                    label: "Дата публикации",
                    callback: (item) => {
                      return item !== null
                        ? moment(item).format("HH:mm DD.MM.YYYY")
                        : "";
                    },
                    isDate: true,
                  },
                  {
                    key: "updated_at",
                    label: "Дата обновления",
                    callback: (item) => {
                      return item !== null
                        ? moment(item).format("HH:mm DD.MM.YYYY")
                        : "";
                    },
                    isDate: true,
                  },
                  {
                    key: "object_type_id",
                    label: "Тип недвижимости",
                    list: data?.objectType,
                    listProp: {
                      value: "id",
                      label: "nameString",
                      search: "object_type_id",
                    },
                  },
                  { key: "title", label: "Название" },
                  {
                    key: "user",
                    label: "Номер телефона",
                    callback: (item) => {
                      return item.phone;
                    },
                  },
                  {
                    key: "status",
                    label: "Состояние",
                    list: data?.listStatus,
                    listProp: {
                      value: "id",
                      label: "name",
                      search: "status",
                    },
                  },
                ]}
                search={true}
                sort={true}
                sortIndex={4}
                pagination={{
                  limit: 10,
                }}
                actions={[
                  {
                    slug: `${url}/form`,
                    icon: "fe-plus",
                    name: "Создать",
                  },
                ]}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
                onChangePage={(page) => setCurrentPage(page)}
                serverRender={true}
                onSearch={(data) => {
                  clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    dispatch(updateQuery({ page: "estates", data: data }));
                  }, 1000);
                }}
                onSort={(data) => {
                  setSort(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
