export const finder = (arr, id) => {
  const item = arr.find((e) => {
    return Number(e.id) === Number(id);
  });
  return item.name;
};

export const POSITION = [
  {
    id: 1,
    name: "Верхнее меню",
  },
  {
    id: 2,
    name: "Нижнее меню",
  },
];

export const TEMPLATES = [
  {
    id: 1,
    name: "Без шаблона",
  },
  {
    id: 2,
    name: "Текстовая страница",
  },
  {
    id: 3,
    name: "Страница каталога",
  },
];

export const STATUS = [
  {
    id: 1,
    name: "Показывать",
  },
  {
    id: 2,
    name: "Скрыть",
  },
];

export const INPUT_TYPE = [
  {
    id: 1,
    name: "поле ввода",
  },
  {
    id: 2,
    name: "единичный выбор",
  },
  {
    id: 3,
    name: "файл",
  },
  {
    id: 4,
    name: "мульти выбор",
  },
  {
    id: 5,
    name: "счетчик",
  },
  {
    id: 6,
    name: "группа",
  },
  {
    id: 7,
    name: "радиобатон",
  },
  {
    id: 8,
    name: "список",
  },
];

export const DISPLAY = [
  {
    id: 1,
    name: "всюду",
  },
  {
    id: 2,
    name: "только на фронте",
  },
  {
    id: 3,
    name: "только в админке",
  },
];

export const CONTENT_TYPE = [
  {
    id: 1,
    name: "произвольный контент",
  },
  {
    id: 2,
    name: "только цифры (целые)",
  },
  {
    id: 3,
    name: "только буквы и пробел",
  },
  {
    id: 4,
    name: "только цифры (целые и дробные)",
  },
];

export const LANG = [
  {
    url: "ru",
    label: "Русский",
  },
  {
    url: "az",
    label: "Azerbaijan",
  },
  {
    url: "en",
    label: "English",
  },
];

export const DROP_TEMPLATE = [
  {
    id: 1,
    name: "Много и небольшие",
  },
  {
    id: 2,
    name: "Большие с рекламой",
  },
];

export const TYPE_FAST_LINK = [
  {
    id: 1,
    name: "Быстрое меню",
  },
  {
    id: 2,
    name: "Сервисы",
  },
];

export const TARGET = [
  {
    id: 1,
    name: "Сдать",
  },
  {
    id: 2,
    name: "Продать",
  },
];

export const ESTATE_TYPE = [
  {
    id: 1,
    name: "Жилая",
  },
  {
    id: 2,
    name: "Коммерческая",
  },
];

export const HAS_COMPLEX = [
  {
    id: 1,
    name: "Да",
  },
  {
    id: 2,
    name: "Нет",
  },
];

export const SIZE_COLUMN = [
  {
    id: 1,
    name: "На всю ширину",
  },
  {
    id: 2,
    name: "1/2 ширины",
  },
  {
    id: 3,
    name: "1/3 ширины",
  },
];

export const SIZE_FOOTER_MENU = [
  {
    id: 1,
    name: "мини",
  },
  {
    id: 2,
    name: "большой",
  },
];

export const POINT_TYPE = [
  {
    id: 1,
    name: "ТЦ",
  },
  {
    id: 2,
    name: "Базар",
  },
  {
    id: 3,
    name: "Больницы",
  },
  {
    id: 4,
    name: "Гос. учреждения",
  },
  {
    id: 5,
    name: "Памятники",
  },
];

export const FAQ_PAGE = [
  {
    id: 1,
    name: "На странице FAQ",
  },
  {
    id: 2,
    name: "На странице контактов",
  },
];

export const USER_TYPE = [
  {
    id: 1,
    name: "обычные пользователи",
  },
  {
    id: 2,
    name: "агенты",
  },
  {
    id: 2,
    name: "застройщики",
  },
];

export const TYPE_PROMOCODE = [
  {
    id: 1,
    name: "многоразовый",
  },
  {
    id: 2,
    name: "уникальный",
  },
];

export const STATUS_COMPLAINT = [
  {
    id: 1,
    name: "новая",
  },
  {
    id: 2,
    name: "отклонена",
  },
  {
    id: 3,
    name: "объявление заблокировано",
  },
];
