import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_CITIES_FILTER } from "../../graphql/Queries/CityQuery";
import { Loader } from "@googlemaps/js-api-loader";
import { GET_ESTATE } from "../../graphql/Queries/EstateQuery";
import {
  MODIFY_ESTATE,
  UPDATE_IMAGE,
} from "../../graphql/Mutations/EstateMutation";
import { TextInput } from "../../components/Form/TextInput";
import { EditProp } from "./FormEstate/EditProp";
import { Col, Modal } from "react-bootstrap";
import Fancybox from "../../components/Fancybox";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import * as Yup from "yup";
import { thumb } from "../../utils/Image";
import { toast } from "react-toastify";

export const FormEstate = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_ESTATE);
  const [others, setOthers] = useState([]);
  const { data, refetch } = useQuery(GET_ESTATE, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      object_type_id: null,
      complex_id: null,
      user_type: null,
      target: null,
      estate_type: null,
      user_id: null,
      currency_id: null,
      ipoteka: null,
      sale_type: null,
      address: "",
      price: "",
      video: "",
      status: null,
      city_id: null,
      area_id: null,
      place_id: null,
      lat: "",
      lng: "",
      title: "",
      phone: "",
      price_by_meter: "",
      kupchaya: null,
      properties: [],
      reject_reason: 0,
      comment: "",
      description: "",
      images: [],
      email: "",
    },
    validationSchema: Yup.object({
      address: Yup.string().required(t("Объязательное поле")),
      reject_reason: Yup.number().when("status", {
        is: (value) => Number(value) === 4,
        then: (schema) => schema.min(1).required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          status: Number(values.status),
          reject_reason: Number(values.reject_reason),
          comment: values.comment,
          description: JSON.stringify(values.description),
          images: JSON.stringify(values.images),
          address: values.address,
          video: values.video,
          email: values.email,
          lat: String(values.lat),
          lng: String(values.lng),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const [cities, setCities] = useState([]);
  const [objectTypes, setObjectTypes] = useState([]);
  const cityQuery = useQuery(GET_CITIES_FILTER, {
    variables: {
      country_id: Number(formik.values.country_id),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const [showModal, toggleModal] = useState("");
  const [rejectReason, setRejectReason] = useState([]);
  const [editProp, setEditProps] = useState(null);
  const [showModalMap, toggleModalMap] = useState(null);
  const [showModalOther, toggleModalOther] = useState(false);
  const [updateImage] = useMutation(UPDATE_IMAGE);

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    version: "weekly",
    libraries: ["places", "marker"],
  });

  const changeImage = (id, action) => {
    updateImage({
      variables: {
        estate_id: Number(formik.values.id),
        image_id: Number(id),
        action,
      },
    })
      .then((resp) => {
        formik.setFieldValue("images", resp.data.updateImage);
      })
      .catch((error) => {
        toast.error(t("Произошла ошибка!"));
      });
  };

  const SortableItem = SortableElement(({ item }) => {
    return (
      <div className={"estate-image"}>
        <div className={"overlap"}>
          <button
            type={"button"}
            onClick={() => changeImage(item.id, "is_index")}
            className={"btn btn-xs btn-info"}
          >
            {t("Главное фото")}
          </button>
          <button
            type={"button"}
            className={"btn btn-xs btn-info"}
            onClick={() => changeImage(item.id, "is_plan")}
          >
            {t("Планировка")}
          </button>
          <button
            type={"button"}
            className={"btn btn-xs btn-danger"}
            onClick={() => changeImage(item.id, "delete")}
          >
            {t("Удалить")}
          </button>
        </div>
        <a data-fancybox="gallery" href={item.url}>
          <img
            src={thumb(item.url)}
            alt={"Image"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <div className={"label-status"}>
            {Number(item.is_index) === 1 && <span>{t("Главное фото")}</span>}
            {Number(item.is_plan) === 1 && <span>{t("Планировка")}</span>}
          </div>
        </a>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {items.map((item, index) => {
          return (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          );
        })}
      </div>
    );
  });

  useEffect(() => {
    if (data) {
      console.log("[Data]", data);
      setOthers(data.estate.data[0].others);
      formik.setFieldValue("id", data.estate.data[0].id);
      formik.setFieldValue("status", String(data.estate.data[0].status));
      formik.setFieldValue("address", String(data.estate.data[0].address));
      formik.setFieldValue("video", data.estate.data[0].video);
      formik.setFieldValue("lat", data.estate.data[0].lat);
      formik.setFieldValue("lng", data.estate.data[0].lng);
      formik.setFieldValue("email", data.estate.data[0].user?.email);
      formik.setFieldValue(
        "reject_reason",
        data.estate.data[0].reject_reason !== null
          ? String(data.estate.data[0].reject_reason)
          : 0,
      );
      formik.setFieldValue("comment", data.estate.data[0].comment);
      formik.setFieldValue("images", data.estate.data[0].images);
      data.estate.data[0].description.map((dic) => {
        formik.setFieldValue(`description.${dic.lang}`, dic.value);
      });
    }
    if (data && data.estateRejectReason) {
      const rejectReason = data.estateRejectReason.map((reason) => {
        const name = reason.title.find((e) => e.lang === i18next.language);
        return { id: reason.id, name: name.value };
      });
      setRejectReason(rejectReason);
    }
  }, [data]);

  // useEffect(() => {
  //   if (cityQuery?.data?.cities) {
  //     setCities(cityQuery?.data?.cities);
  //   }
  // }, [cityQuery.data]);

  const deviceType = (type) => {
    switch (type) {
      case "mobile":
        return t("Телефон");

      case "tablet":
        return t("Планшет");

      case "desktop":
        return t("ПК/ноутбук");

      default:
        return t("Не указано");
    }
  };

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить объявление" : "Редактировать объявление"}
        breadcrumbs={[
          {
            slug: url,
            name: "Объявления",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <Modal show={showModalMap}>
                <Modal.Header closeButton onClick={() => toggleModalMap(false)}>
                  <Modal.Title>{t("Просмотр на карте")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div id={"map"} style={{ width: "100%", height: 300 }} />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => toggleModalMap(false)}
                  >
                    {t("Закрыть")}
                  </Button>
                </Modal.Footer>
              </Modal>
              <form className="row" onSubmit={formik.handleSubmit}>
                <Col md={12}>
                  <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                    }}
                  >
                    <SortableList
                      items={formik.values.images}
                      axis={"xy"}
                      onSortEnd={({
                        oldIndex,
                        newIndex,
                        collection,
                        isKeySorting,
                      }) => {
                        let _items = [...formik.values.images];
                        let item = _items.splice(oldIndex, 1)[0];
                        _items.splice(newIndex, 0, item);
                        formik.setFieldValue("images", _items);
                      }}
                    />
                  </Fancybox>
                </Col>
                <div className="col-md-6">
                  <div className="table-responsive mb-3">
                    <table className="table table-centered border mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th colSpan="2">{t("Данные объявления")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">ID:</th>
                          <td>
                            <a
                              target={"_blank"}
                              href={
                                "https://city.az/item/" + data.estate.data[0].id
                              }
                            >
                              {data.estate.data[0].id}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Тип объекта")}:</th>
                          <td>{data.estate.data[0].object_type_id}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Тип пользователя")}:</th>
                          <td>{data.estate.data[0].user_type}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Цель")}:</th>
                          <td>{data.estate.data[0].target}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Тип недвижимости")}:</th>
                          <td>{data.estate.data[0].estate_type}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Пользователь")}:</th>
                          <td>
                            <div>ID: {data.estate.data[0].user?.id}</div>
                            <div>Имя: {data.estate.data[0].user?.name}</div>
                            <div>
                              Телефон: {data.estate.data[0].user?.phone}
                            </div>
                            <div>
                              Баланс:
                              <div>
                                {" "}
                                - основной: {data.estate.data[0].user?.balance}
                              </div>
                              <div>
                                - бонусный:{" "}
                                {data.estate.data[0].user?.bonus_balance}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TextInput
                                boxClass={"w100"}
                                value={formik.values.email}
                                touched={formik.touched.email}
                                error={formik.errors.email}
                                name={"email"}
                                label={"Email"}
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    `email`,
                                    event.target.value,
                                  );
                                }}
                              />
                              {data.estate.data[0].user?.email_verified && (
                                <span
                                  className={"badge bg-success"}
                                  style={{ marginLeft: 5 }}
                                >
                                  <i
                                    className={"mdi mdi-account-check"}
                                    style={{ fontSize: 14 }}
                                  />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Заголовок")}:</th>
                          <td>{data.estate.data[0].title}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Жилая зона")}:</th>
                          <td>{data.estate.data[0]?.complex?.nameString}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <TextInput
                              boxClass={""}
                              value={formik.values.address}
                              touched={formik.touched.address}
                              error={formik.errors.address}
                              name={"address"}
                              label={"Адрес"}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  `address`,
                                  event.target.value,
                                );
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Город")}:</th>
                          <td>
                            {
                              data.estate.data[0].city_id?.name.find(
                                (item) => item.lang === i18next.language,
                              ).value
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Район")}:</th>
                          <td>
                            {
                              data.estate.data[0].area_id?.name.find(
                                (item) => item.lang === i18next.language,
                              )?.value
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Поселок")}:</th>
                          <td>
                            {
                              data.estate.data[0].place_id?.name.find(
                                (item) => item.lang === i18next.language,
                              ).value
                            }
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Координаты")}:</th>
                          <td>
                            {data.estate.data[0].lat}, {data.estate.data[0].lng}
                            {data.estate.data[0].lat !== "" &&
                              data.estate.data[0].lng !== "" && (
                                <a
                                  href={"#"}
                                  style={{ marginLeft: 10 }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleModalMap(true);
                                    loader
                                      .load()
                                      .then((google) => {
                                        const map = new google.maps.Map(
                                          document.getElementById("map"),
                                          {
                                            center: {
                                              lat: Number(
                                                data.estate.data[0].lat,
                                              ),
                                              lng: Number(
                                                data.estate.data[0].lng,
                                              ),
                                            },
                                            zoom: 16,
                                          },
                                        );
                                        const draggableMarker =
                                          new google.maps.Marker({
                                            position: {
                                              lat: Number(
                                                data.estate.data[0].lat,
                                              ),
                                              lng: Number(
                                                data.estate.data[0].lng,
                                              ),
                                            },
                                            map,
                                            title:
                                              data.estate.data[0].lat +
                                              ", " +
                                              data.estate.data[0].lng,
                                            draggable: true,
                                          });

                                        draggableMarker.addListener(
                                          "dragend",
                                          (event) => {
                                            const position =
                                              draggableMarker.position;
                                            formik.setFieldValue(
                                              "lat",
                                              position.lat(),
                                            );
                                            formik.setFieldValue(
                                              "lng",
                                              position.lng(),
                                            );
                                          },
                                        );
                                      })
                                      .catch((e) => {
                                        // do something
                                      });
                                  }}
                                >
                                  {t("Посмотреть на карте")}
                                </a>
                              )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Дата создания")}:</th>
                          <td>{data.estate.data[0].created_at}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Дата публикации")}:</th>
                          <td>{data.estate.data[0].published_at}</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            {t("Дата последнего обновления")}:
                          </th>
                          <td>{data.estate.data[0].updated_at}</td>
                        </tr>
                        <tr>
                          <th scope="row">{t("Цена")}:</th>
                          <td>{data.estate.data[0].price}</td>
                        </tr>
                        {Number(data.estate.data[0].status) === 1 && (
                          <tr>
                            <th scope="row">{t("Последний шаг")}:</th>
                            <td>{data.estate.data[0].last_step}</td>
                          </tr>
                        )}
                        <tr>
                          <th scope="row">{t("Тип устройства")}:</th>
                          <td>{deviceType(data.estate.data[0].device_type)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="table-responsive">
                    <table className="table table-centered border mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th colSpan="2">{t("Свойства")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.estate.data[0].properties.map((prop) => {
                          return prop.value !== "" && prop.value !== null ? (
                            <tr
                              key={prop.id}
                              className={"allow-edit"}
                              onClick={() => {
                                setEditProps(prop);
                              }}
                            >
                              <th scope="row">
                                {
                                  prop?.property?.name.find(
                                    (name) => name.lang === i18next.language,
                                  ).value
                                }
                                :
                              </th>
                              <td>{prop.value !== "" ? prop.value : "--"}</td>
                            </tr>
                          ) : (
                            ""
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <h4>{t("Другие объявления пользователя")}</h4>
                  <table className="table table-centered border mb-0">
                    <tbody>
                      {others.slice(0, 5).map((other) => {
                        const status = data?.list.find((e) => {
                          return Number(e.id) === Number(other.status);
                        });
                        return (
                          <tr>
                            <td>{status.name}</td>
                            <td>{other.published_at}</td>
                            <td>{other.object_type_id}</td>
                            <td>
                              <a
                                href={
                                  process.env.REACT_APP_API_URL +
                                  "/item/" +
                                  other.id
                                }
                                target={"_blank"}
                              >
                                {other.id}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {others.length > 5 && (
                    <button
                      type={"button"}
                      className={"btn btn-info btn-sm"}
                      onClick={() => toggleModalOther(true)}
                    >
                      {t("Показать все")}
                    </button>
                  )}
                  <Modal show={showModalOther}>
                    <Modal.Header
                      closeButton
                      onClick={() => toggleModalOther(false)}
                    >
                      <Modal.Title>{t("Все объявления")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <table className="table table-centered border mb-0">
                        <tbody>
                          {others.map((other) => {
                            const status = data?.list.find((e) => {
                              return Number(e.id) === Number(other.status);
                            });
                            return (
                              <tr>
                                <td>{status.name}</td>
                                <td>{other.published_at}</td>
                                <td>{other.object_type_id}</td>
                                <td>
                                  <a
                                    href={
                                      process.env.REACT_APP_API_URL +
                                      "/item/" +
                                      other.id
                                    }
                                    target={"_blank"}
                                  >
                                    {other.id}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => toggleModalOther(false)}
                      >
                        {t("Закрыть")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <EditProp
                  prop={editProp}
                  onCancel={() => setEditProps(null)}
                  estate_id={Number(searchParams.get("id"))}
                  onUpdate={() => {
                    refetch();
                    setEditProps(null);
                  }}
                />
                <div className="col-md-6">
                  <Selected
                    label={"Состояние"}
                    onChange={(value) => {
                      formik.setFieldValue(`status`, value);
                    }}
                    error={formik.errors.status}
                    name={"status"}
                    rows={{ value: "id", label: "name" }}
                    staticOptions={data?.list}
                    value={formik.values.status}
                  />
                </div>
                {Number(formik.values.status) === 4 && (
                  <div className="col-md-6">
                    <Selected
                      label={"Причина отклонения"}
                      onChange={(value) => {
                        formik.setFieldValue(`reject_reason`, value);
                      }}
                      error={formik.errors.reject_reason}
                      touched={formik.touched.reject_reason}
                      name={"reject_reason"}
                      rows={{ value: "id", label: "name" }}
                      staticOptions={rejectReason}
                      value={formik.values.reject_reason}
                    />
                  </div>
                )}
                <div className="col-md-12">
                  <TextInput
                    label={"Youtube"}
                    value={formik.values.video}
                    onChange={(event) => {
                      formik.setFieldValue(`video`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.video}
                    touched={formik.touched.video}
                    name={"video"}
                  />
                </div>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Описание"}
                    value={formik.values.description}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`description.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.description}
                    touched={formik.touched.description}
                    name={"description"}
                    type={"textarea"}
                  />
                </div>
                <div className="col-md-12">
                  <TextInput
                    type={"textarea"}
                    label={"Комментарий"}
                    value={formik.values.comment}
                    onChange={(event) => {
                      formik.setFieldValue(`comment`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.comment}
                    touched={formik.touched.comment}
                    name={"comment"}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
