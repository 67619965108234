import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components/Wrapper";
import { PageTitle } from "../../components/PageTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Notification } from "../../utils/Notification";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { MultilangTextInput } from "../../components/Form/MultilangTextInput";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Preload } from "../../components/Preload";
import { Selected } from "../../components/Form/Selected";
import { useMutation, useQuery } from "@apollo/client";
import i18next from "i18next";
import { GET_FAQ } from "../../graphql/Queries/FaqQuery";
import { FaqTabsFormComponent } from "../../components/forms/FaqTabsFormComponent";
import { MODIFY_FAQ } from "../../graphql/Mutations/FaqMutation";
import { GET_COMPLAINT } from "../../graphql/Queries/ComplaintQuery";
import { TextInput } from "../../components/Form/TextInput";
import { MODIFY_COMPLAINT } from "../../graphql/Mutations/ComplaintMutation";
import { useDispatch } from "react-redux";
import { addComplaint } from "../../redux/slices/badgeSlice";
import { GET_SERVICE } from "../../graphql/Queries/ServiceQuery";
import { FileInput } from "../../components/Form/FileInput";
import { formatRowLang } from "../../utils/Helper";
import { CountryFormComponent } from "../../components/forms/CountryForm";
import { MODIFY_SERVICE } from "../../graphql/Mutations/ServiceMutation";

export const ServiceForm = ({ url }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const id = location.state ? location.state.id : null;
  let [searchParams, setSearchParams] = useSearchParams();
  const [modify] = useMutation(MODIFY_SERVICE);
  const dispatch = useDispatch();
  const { data } = useQuery(GET_SERVICE, {
    variables: {
      id: Number(searchParams.get("id")),
      lang: i18next.language,
    },
    fetchPolicy: "no-cache",
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: {
        ru: "",
        az: "",
        en: "",
      },
      name_short: {
        ru: "",
        az: "",
        en: "",
      },
      text: {
        ru: "",
        az: "",
        en: "",
      },
      key: "",
      icon: "",
      variants: [
        {
          name: {
            ru: "",
            az: "",
            en: "",
          },
          amount: "",
          prices: [
            {
              price: "",
              user_type: 1,
            },
          ],
        },
      ],
    },
    onSubmit: (values) => {
      setLoading(true);
      modify({
        variables: {
          id: Number(values.id),
          name: JSON.stringify(values.name),
          name_short: JSON.stringify(values.name_short),
          text: JSON.stringify(values.text),
          key: values.key,
          icon: JSON.stringify(values.icon),
          variants: JSON.stringify(values.variants),
        },
      })
        .then((response) => {
          navigate(url);
          Notification(t("Все хорошо!"), "success");
        })
        .catch((error) => {
          Notification(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    validationSchema: Yup.object().shape({
      variants: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.object().shape({
              az: Yup.string().required("Объязательное поле"),
            }),
            prices: Yup.array()
              .of(
                Yup.object().shape({
                  price: Yup.string().required("Объязательное поле"),
                }),
              )
              .required("Объязательное поле"),
          }),
        )
        .required("Объязательное поле"),
    }),
  });

  useEffect(() => {
    if (data && data.service[0]) {
      data.service[0].name.map((dic) => {
        formik.setFieldValue(`name.${dic.lang}`, dic.value);
      });
      data.service[0].name_short.map((dic) => {
        formik.setFieldValue(`name_short.${dic.lang}`, dic.value);
      });
      data.service[0].text.map((dic) => {
        formik.setFieldValue(`text.${dic.lang}`, dic.value);
      });
      if (data.service[0].variants.length > 0) {
        const variants = [];
        data.service[0].variants.map((v) => {
          const variantNames = {};
          v.name.map((dic) => {
            variantNames[dic.lang] = dic.value;
          });
          variants.push({
            name: variantNames,
            amount: v.amount,
            prices: v.prices,
          });
        });
        formik.setFieldValue("variants", variants);
      }
      formik.setFieldValue("id", data.service[0].id);
      formik.setFieldValue("key", data.service[0].key);
      formik.setFieldValue("icon", JSON.parse(data.service[0].icon));
    }
  }, [data]);

  useEffect(() => {
    console.log("[Log]", formik.errors);
  }, [formik.errors]);

  if (!data) {
    return <Preload />;
  }

  return (
    <Wrapper>
      <PageTitle
        title={id === null ? "Добавить услугу" : "Редактировать услугу"}
        breadcrumbs={[
          {
            slug: url,
            name: "Услугу",
          },
        ]}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название"}
                    value={formik.values.name}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name}
                    touched={formik.touched.name}
                    name={"name"}
                  />
                </div>
                <div className="col-md-6">
                  <MultilangTextInput
                    label={"Название (краткое)"}
                    value={formik.values.name_short}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`name_short.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.name_short}
                    touched={formik.touched.name_short}
                    name={"name_short"}
                  />
                </div>
                <div className="col-md-12">
                  <MultilangTextInput
                    label={"Описание"}
                    value={formik.values.text}
                    onChange={(text, lang) => {
                      formik.setFieldValue(`text.${lang}`, text);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.text}
                    touched={formik.touched.text}
                    name={"text"}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    label={"Ключ"}
                    value={formik.values.key}
                    onChange={(event) => {
                      formik.setFieldValue(`key`, event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.key}
                    touched={formik.touched.key}
                    name={"key"}
                  />
                </div>
                <div className="col-md-12">
                  <FileInput
                    label={"Иконка"}
                    onChange={(file) => {
                      formik.setFieldValue(`icon`, file);
                    }}
                    onDelete={() => {
                      formik.setFieldValue(`icon`, "");
                    }}
                    value={formik.values.icon}
                    multilang={false}
                  />
                </div>
                <h4>{t("Варианты")}</h4>
                <div className="col-md-12">
                  {formik.values.variants.map((v, i) => (
                    <div className="card">
                      <div className="card-body">
                        <div className="card-widgets">
                          {i === formik.values.variants.length - 1 && (
                            <a
                              href="javascript:;"
                              onClick={() => {
                                const _variants = [...formik.values.variants];
                                _variants.push({
                                  name: {
                                    ru: "",
                                    az: "",
                                    en: "",
                                  },
                                  amount: "",
                                  prices: [
                                    {
                                      price: "",
                                      user_type: 1,
                                    },
                                  ],
                                });
                                formik.setFieldValue("variants", _variants);
                              }}
                              data-toggle="reload"
                            >
                              <i className="mdi mdi-plus"></i>
                            </a>
                          )}
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              const _variants = [...formik.values.variants];
                              _variants.splice(i, 1);
                              formik.setFieldValue("variants", _variants);
                            }}
                            data-toggle="remove"
                          >
                            <i className="mdi mdi-close"></i>
                          </a>
                        </div>

                        <div id="cardCollpase1" className="collapse pt-3 show">
                          <div className={"row"}>
                            <div className="col-md-6">
                              <MultilangTextInput
                                label={"Название"}
                                value={formik.values.variants[i].name}
                                onChange={(text, lang) => {
                                  formik.setFieldValue(
                                    `variants[${i}].name.${lang}`,
                                    text,
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                //error={formik.errors.variants[i].name}
                                //touched={formik.touched.variants[i].name}
                                name={"variant_name"}
                                fullError={
                                  formik.errors.variants !== undefined
                                    ? formik.errors.variants[i]?.name?.az
                                    : false
                                }
                              />
                            </div>
                            <div className="col-md-6 mt-4">
                              <TextInput
                                label={"Количество"}
                                value={formik.values.variants[i].amount}
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    `variants[${i}].amount`,
                                    event.target.value,
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                //error={formik.errors.variants[i].name}
                                //touched={formik.touched.variants[i].name}
                                name={"variant_amount"}
                              />
                            </div>
                            {v.prices.map((price, j) => {
                              return (
                                <div className="card">
                                  <div className="card-body">
                                    <div className="card-widgets">
                                      {j ===
                                        formik.values.variants[i].prices
                                          .length -
                                          1 && (
                                        <a
                                          href="javascript:;"
                                          onClick={() => {
                                            const _variants = [
                                              ...formik.values.variants[i]
                                                .prices,
                                            ];
                                            _variants.push({
                                              price: "",
                                              user_type: 1,
                                            });
                                            formik.setFieldValue(
                                              "variants[" + i + "].prices",
                                              _variants,
                                            );
                                          }}
                                          data-toggle="reload"
                                        >
                                          <i className="mdi mdi-plus"></i>
                                        </a>
                                      )}
                                      <a
                                        href="javascript:void(0);"
                                        onClick={() => {
                                          const _prices = [
                                            ...formik.values.variants[i].prices,
                                          ];
                                          _prices.splice(j, 1);
                                          formik.setFieldValue(
                                            "variants[" + i + "].prices",
                                            _prices,
                                          );
                                        }}
                                        data-toggle="remove"
                                      >
                                        <i className="mdi mdi-close"></i>
                                      </a>
                                    </div>

                                    <div
                                      id="cardCollpase1"
                                      className="collapse pt-3 show"
                                    >
                                      <div className={"row"}>
                                        <div className="col-md-6">
                                          <Selected
                                            label={"Тип пользователя"}
                                            onChange={(value) => {
                                              formik.setFieldValue(
                                                `variants[${i}].prices[${j}].user_type`,
                                                value,
                                              );
                                            }}
                                            //error={formik.errors.country_id}
                                            name={"variant_price_user_type"}
                                            staticOptions={[
                                              {
                                                id: 1,
                                                name: "Обычные пользователи",
                                              },
                                            ]}
                                            value={
                                              formik.values.variants[i].prices[
                                                j
                                              ].user_type
                                            }
                                            style={{ paddingRight: 0 }}
                                          />
                                        </div>
                                        <div className="col-md-6">
                                          <TextInput
                                            label={"Цена"}
                                            value={
                                              formik.values.variants[i].prices[
                                                j
                                              ].price
                                            }
                                            onChange={(event) => {
                                              formik.setFieldValue(
                                                `variants[${i}].prices[${j}].price`,
                                                event.target.value,
                                              );
                                            }}
                                            onBlur={formik.handleBlur}
                                            //error={formik.errors.variants[i].name}
                                            //touched={formik.touched.variants[i].name}
                                            name={"variant_price_price"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    label={"Сохранить"}
                    loading={loading}
                    style={"btn btn-primary w-md"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
